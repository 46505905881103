// Chakra imports
import {
  Button,
  Flex, Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  Text,
  Spinner,
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

import { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

// Assets
import { ProjectDetails } from '../../../../components/addEditProjects/ProjectDetails';
import { ProjectInvoice } from '../../../../components/addEditProjects/ProjectInvoice';
import { ProjectPermissions } from '../../../../components/addEditProjects/ProjectPermissions';
import { ProjectTasks } from '../../../../components/addEditProjects/ProjectTasks';
import { useProjectStore } from '../../../../contexts/globalStoreProjects';
import { t } from 'i18next';
import { MdChevronLeft } from "react-icons/md";
import { ProjectCompany } from 'components/addEditProjects/ProjectCompany';
import { useCompanyStore } from 'contexts/globalStoreCompanies';
import { useCostCenterStore } from 'contexts/globalStoreCostCenters';
import { ProjectCostCenters } from 'components/addEditProjects/ProjectCostCenters';
import { checkIfUserProjectCreator, checkIfUserFakturaAdmin, checkIsUserAdminOnProject } from 'utils/roleHelpers';
import useUserDataRolesStore from 'contexts/authStore';
import useFormErrorsStore from 'contexts/formErrorsStore';
import ProjectRates from 'components/addEditProjects/ProjectRates';

export default function AddEditProject() {
  const toast = useToast();
  let { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);

  const [isUserAdminOnProject, setIsUserAdminOnProject] = useState(false);
  const [isUserFinancialAdminOnProject, setIsUserFinancialAdminOnProject] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);

  const userData = useUserDataRolesStore((state) => state.userData);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);
  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);
  const isUserProjectCreator = useMemo(() => checkIfUserProjectCreator(userRoles), [userRoles]);

  useEffect(() => {
    const checkIfAdmin = () => {
      if (id) {
        const isAdmin = checkIsUserAdminOnProject(allProjectUsers, userData.id, Number(id));
        setIsUserAdminOnProject(isAdmin);
        const isFinancialAdmin = checkIsUserAdminOnProject(allProjectUsers, userData.id, Number(id), true);
        setIsUserFinancialAdminOnProject(isFinancialAdmin);
      }
    }
    checkIfAdmin();

    setViewOnly(!isUserFakturaAdmin && !isUserAdminOnProject && !isUserFinancialAdminOnProject && !(isUserProjectCreator && !id));
  }, [allProjectUsers,
    id,
    isUserAdminOnProject,
    isUserProjectCreator,
    isUserFakturaAdmin,
    isUserFinancialAdminOnProject,
    navigate,
    userData.id]);

  const fetchCompanies = useCompanyStore((state) => state.fetchCompanies);
  const fetchTasks = useProjectStore((state) => state.fetchTasks);
  const fetchCostCenters = useCostCenterStore((state) => state.fetchCostCenters);

  const fetchSingleProject = useProjectStore(
    (state) => state.fetchSingleProject,
  );
  const validationCheck = useProjectStore((state) => state.validation);
  const resetValidation = useProjectStore((state) => state.resetValidation);
  const resetData = useProjectStore((state) => state.resetProject);
  const submitData = useProjectStore((state) => state.addOrEditProjectCall);
  const [enableSubmit, setEnableSubmit] = useState(false);

  const errors = useFormErrorsStore((state) => state.errors);
  const resetErrors = useFormErrorsStore((state) => state.resetErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const setShowErrors = useFormErrorsStore((state) => state.setShowErrors);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    fetchTasks();
    fetchCompanies();
    fetchCostCenters();
    let isCurrent = true;
    if (isCurrent) {
      if (id) {
        fetchSingleProject(Number(id))
          .then((res) => {
            if (!res || res.type !== 'success') {
              toast({
                title: t('error', { ns: ['labels'] }),
                description: res.message,
                status: res.type,
              });
            }

            setIsLoading(false);
          })
          .catch((err) => {
            toast({
              title: t('error', { ns: ['labels'] }),
              description: err.message,
              status: err.type,
            });

            setIsLoading(false);
          });
      } else {
        if (location.pathname === '/projects/create') {
          resetData();
          resetValidation();
        }

        setIsLoading(false);
      }
    }

    return () => {
      resetData();
      isCurrent = false;
    };
  }, [fetchCompanies, fetchCostCenters, fetchSingleProject, fetchTasks, id, location.pathname, resetData, resetValidation, toast]);

  useEffect(() => {
    if (
      validationCheck.detailsValidation &&
      validationCheck.companyValidation &&
      ((!isUserFinancialAdminOnProject && !isUserFakturaAdmin) || validationCheck.invoiceValidation) &&
      ((!isUserFinancialAdminOnProject && !isUserFakturaAdmin) || validationCheck.ratesValidation) &&
      validationCheck.costCenterValidation &&
      validationCheck.taskValidation &&
      validationCheck.permissionValidation
    ) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [isUserFakturaAdmin, isUserFinancialAdminOnProject, validationCheck]);

  useEffect(() => {
    setShowErrors(false);
    resetErrors();
    return () => { resetErrors() };
  }, [resetErrors, setShowErrors]);

  const handleDataSubmit = useCallback(() => {
    if (!enableSubmit) {
      setShowErrors(true);
      return;
    } else {
      setShowErrors(false);
    }
    setIsLoading(true);
    submitData(userData.id)
      .then((res: any) => {
        if (res) {
          toast({
            title: res.type === 'success' ? t('success', { ns: ['labels'] }) : t('error', { ns: ['labels'] }),
            description: res.message,
            status: res.type,
          });
          if (res.projectId) {
            navigate(`/projects/edit/${res.projectId}`);
            fetchSingleProject(res.projectId);
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        toast({
          title: t('error', { ns: ['labels'] }),
          description: err.message,
          status: err.type,
        });
        setIsLoading(false);
      });
  }, [enableSubmit, fetchSingleProject, navigate, setShowErrors, submitData, toast, userData.id]);

  const tabComponents = [
    {
      title: t('details', { ns: ['labels'] }),
      component: <ProjectDetails viewOnly={viewOnly} />,
      validation: validationCheck.detailsValidation
    },
    {
      title: t('company', { ns: ['labels'] }),
      component: <ProjectCompany viewOnly={viewOnly} />,
      validation: validationCheck.companyValidation
    },
    {
      title: t('costCenters', { ns: ['labels'] }),
      component: <ProjectCostCenters viewOnly={viewOnly} />,
      validation: validationCheck.costCenterValidation
    },
    {
      title: t('tasks', { ns: ['labels'] }),
      component: <ProjectTasks viewOnly={viewOnly} />,
      validation: validationCheck.taskValidation
    },
    {
      title: t('permissions', { ns: ['labels'] }),
      component: <ProjectPermissions viewOnly={viewOnly} isUserFinancialAdminOnProject={isUserFinancialAdminOnProject} />,
      validation: validationCheck.permissionValidation
    },
  ];

  if (isUserFinancialAdminOnProject || isUserFakturaAdmin) {
    tabComponents.splice(2, 0, {
      title: t('invoice', { ns: ['labels'] }),
      component: <ProjectInvoice viewOnly={viewOnly} />,
      validation: validationCheck.invoiceValidation
    });
    tabComponents.splice(6, 0, {
      title: t('rates', { ns: ['labels'] }),
      component: <ProjectRates viewOnly={viewOnly} isUserFinancialAdminOnProject={isUserFinancialAdminOnProject} />,
      validation: validationCheck.ratesValidation
    });
  }

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }} alignItems='center'>
      <Tabs variant="enclosed" maxWidth={1366} width="100%">
        <TabList>
          {tabComponents.map((tab, index) => (
            <Tab data-test-id={`${tab.title}-tab`} key={index} color={showErrors && !tab.validation ? 'red.500' : ''}>{tab.title}</Tab>
          ))}
        </TabList>

        <TabPanels>
          {isLoading ? <Spinner /> : tabComponents.map((tab, index) => (
            <TabPanel key={index}>
              <Card>
                <Flex justifyContent="space-between" mb={4}>
                  <Button
                    variant="outline"
                    fontSize="sm"
                    borderRadius="12px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    data-test-id="project-back-to-list-button"
                    onClick={() => navigate('/projects')}
                  >
                    <Icon as={MdChevronLeft} mr={1} />
                    {t('back', { ns: ['actions'] })}
                  </Button>
                  {!viewOnly ? <Button
                    variant="darkBrand"
                    fontSize="sm"
                    borderRadius="16px"
                    w={{ base: '128px', md: '148px' }}
                    h="46px"
                    data-test-id="project-submit-button"
                    onClick={handleDataSubmit}
                  >
                    {t('submit', { ns: ['labels'] })}
                  </Button> : null}
                </Flex>
                {tab.component}
              </Card>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      {showErrors && errors?.length ? <Card ml={8} mb={4}>
        <Flex justifyContent="space-between" direction={'column'} mb={4}>
          {errors.map((error, index) => (
            <Text color="red.500" key={index}>{error}</Text>
          ))}
        </Flex>
      </Card> : null}
    </Flex>
  );
}
