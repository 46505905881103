// chakra imports
import {
  Icon,
} from '@chakra-ui/react';
// Assets
import useUserDataRolesStore from "../../../contexts/authStore";
import CustomMenuLink from './CustomMenuLink';
import {
  MdAccessTime,
  MdBarChart,
  MdDomain,
  MdHome,
  MdMediation,
  MdOutlineFactCheck,
  MdOutlinePaid
} from 'react-icons/md';
import { useMemo } from 'react';
import { useProjectStore } from 'contexts/globalStoreProjects';
import {
  checkIfUserProjectCreator,
  checkIfUserFakturaAdmin,
  checkIsUserAdminInAnyProject,
  checkIsUserFinancialAdminInAnyProject,
  checkIfUserCompanyCreator,
  checkIfUserCostCenterCreator,
  checkIsUserInAnyProject
} from 'utils/roleHelpers';

export function SidebarLinks(props: {
  hovered: boolean;
  mini?: boolean;
}) {
  const { hovered, mini } = props;

  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);

  const userData = useUserDataRolesStore((state) => state.userData);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);

  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);
  const isUserProjectCreator = useMemo(() => checkIfUserProjectCreator(userRoles), [userRoles]);
  const isUserCompanyCreator = useMemo(() => checkIfUserCompanyCreator(userRoles), [userRoles]);
  const isUserCostCenterCreator = useMemo(() => checkIfUserCostCenterCreator(userRoles), [userRoles]);

  const isUserInAnyProject = useMemo(() =>
    checkIsUserInAnyProject(allProjectUsers, userData.id), [allProjectUsers, userData]);

  const isUserAdminInAnyProject = useMemo(() =>
    checkIsUserAdminInAnyProject(allProjectUsers, userData.id), [allProjectUsers, userData]);

  const isUserFinancialAdminInAnyProject = useMemo(() =>
    checkIsUserFinancialAdminInAnyProject(allProjectUsers, userData.id), [allProjectUsers, userData]);

  return (
    <>
      <CustomMenuLink
        title="home"
        path="/"
        mini={mini}
        hovered={hovered}
        icon={<Icon as={MdHome} width="20px" height="20px" color="inherit" />}
        data-test-id="main-menu-home-link"
      />

      {isUserFakturaAdmin || isUserProjectCreator || isUserAdminInAnyProject || isUserFinancialAdminInAnyProject ?
        <CustomMenuLink
          title="projects"
          path="/projects"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdMediation} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-projects-link"
        />
        : null}
      {isUserCompanyCreator || isUserAdminInAnyProject || isUserFinancialAdminInAnyProject ?
        <CustomMenuLink
          title="companies"
          path="/companies"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdDomain} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-companies-link"
        />
        : null}
      {isUserCostCenterCreator || isUserAdminInAnyProject || isUserFinancialAdminInAnyProject ?
        <CustomMenuLink
          title="cost-centers"
          path="/cost-centers"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdOutlineFactCheck} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-cost-centers-link"
        />
        : null
      }

      {isUserInAnyProject
        || isUserAdminInAnyProject
        || isUserFinancialAdminInAnyProject ? (
        <CustomMenuLink
          title="bookings"
          path="/faktura/bookings"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdAccessTime} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-bookings-link"
        />) : null}

      {isUserAdminInAnyProject || isUserFinancialAdminInAnyProject ? (
        <CustomMenuLink
          title="reports"
          path="/faktura/reports"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdBarChart} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-reports-link"
        />
      ) : null}

      {isUserFinancialAdminInAnyProject ? (
        <CustomMenuLink
          title="invoices"
          path="/faktura/invoices"
          mini={mini}
          hovered={hovered}
          icon={<Icon as={MdOutlinePaid} width="20px" height="20px" color="inherit" />}
          data-test-id="main-menu-invoices-link"
        />
      ) : null}
    </>
  )
}

export default SidebarLinks;
