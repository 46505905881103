import { useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  Flex,
  Text,
  useColorModeValue,
  Icon,
} from '@chakra-ui/react';
import { MdCircle } from 'react-icons/md';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import { ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import useLoaderStore from 'contexts/globalStore';

import { useReportsStore } from 'contexts/globalStoreReports';
import { User, useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';
import { convertMinutesToHHmmForInput } from 'utils/dateHelpers';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = {
  id: number;
  user: string;
  project: string;
  currency: string;
  totalResourceCost: string;
  totalDuration: string;
  unit: string;
  totalClientCost: string;
};
export default function SearchTableTotalsByResource() {
  const textColor = useColorModeValue('navy.700', 'white');

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const { fakturaUsers } = useFakturaUsersStore(
    (state: any) => ({ fakturaUsers: state.users }),
  );
  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);
  const getProjectByProjectUserId = useProjectStore((state) => state.getProjectByProjectUserId);

  const { fakturaReportsByResource } = useReportsStore(
    (state: any) => ({ fakturaReportsByResource: state.reportsByResource }),
  );

  const { reportsByResourceMeta } = useReportsStore(
    (state: any) => ({ reportsByResourceMeta: state.reportsByResourceMeta }),
  );

  const loadingState = useLoaderStore((state) => state.isLoading);

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaReportsByResource);
    }
    return () => {
      isCurrent = false;
    };
  }, [fakturaReportsByResource]);

  const [globalFilter, setGlobalFilter] = useState('');
  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('user', {
      id: 'user',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-total-by-resource-table-header-user"
        >
          {t('user', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        const userId = info.row.original.userId;

        const projectUser = allProjectUsers.find(
          (projectUser: ProjectUser) => Number(projectUser.id) === Number(userId)
        )
        if (!projectUser) {
          return null;
        }

        const user = fakturaUsers.find((user: User) =>
          projectUser.userId === user.userId);

        if (user) {
          const userDisplay = `${user.name} (${user.email})`;
          return (
            <Text color={textColor} fontSize="md" fontWeight="500"
              data-test-id={`reports-total-by-resource-table-cell-user-${id}`}
            >
              {userDisplay}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),
    columnHelper.accessor('project', {
      id: 'project',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-total-by-resource-table-header-project"
        >
          {t('project', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        const userId = info.row.original.userId;
        const project = getProjectByProjectUserId(userId)

        if (project) {
          return (
            <Flex alignItems={'center'} data-test-id={`reports-total-by-resource-table-cell-project-${id}`}>
              <Text
                color={textColor}
                key={project.id}
                fontSize="md"
                fontWeight="500"
              >
                {project.title}
              </Text>
              <Icon
                ml="5px"
                color={project.isActive ? 'green.500' : 'gray.500'}
                as={MdCircle}
                w="10px"
                h={'10px'}
              />
            </Flex>
          );
        } else {
          return null;
        }
      },
    }),

    columnHelper.accessor('totalDuration', {
      id: 'totalDuration',
      header: () => (
        <Text
          justifyContent="space-between"
          textAlign={'center'}
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-total-by-resource-table-header-totalDuration"
        >
          {t('totalAmountHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        return (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              textAlign: 'center',
            }}
            data-test-id={`reports-total-by-resource-table-cell-totalDuration-${id}`}
          >
            {convertMinutesToHHmmForInput(Number(info.getValue()))}
          </Text>
        );
      },
    }),

    columnHelper.accessor('unit', {
      id: 'unit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-total-by-resource-table-header-unit"
        >
          {t('unit', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500"
            data-test-id={`reports-total-by-resource-table-cell-unit-${id}`}
          >
            {t('hours', { ns: ['labels'] })}
          </Text>
        );
      },
    }),

    columnHelper.accessor('totalResourceCost', {
      id: 'totalResourceCost',
      header: () => (
        <Text
          textAlign={'center'}
          justifyContent="space-between"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-total-by-resource-table-header-totalResourceCost"
        >
          {t('total_resource_cost', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              textAlign: 'center',
            }}
            data-test-id={`reports-total-by-resource-table-cell-totalResourceCost-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),

    columnHelper.accessor('totalClientCost', {
      id: 'totalClientCost',
      header: () => (
        <Text
          textAlign={'center'}
          justifyContent="space-between"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-total-by-resource-table-header-totalClientCost"
        >
          {t('total_client_cost', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.id;
        return (
          <Text
            color={textColor}
            fontSize="md"
            fontWeight="500"
            style={{
              overflowWrap: 'break-word',
              wordBreak: 'break-all',
              textAlign: 'center',
            }}
            data-test-id={`reports-total-by-resource-table-cell-totalClientCost-${id}`}
          >
            {info.getValue()}
          </Text>
        );
      },
    }),
    columnHelper.accessor('currency', {
      id: 'currency',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="reports-total-by-resource-table-header-currency"
        >
          {t('currency', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.id;
        const userId = info.row.original.userId;
        const project = getProjectByProjectUserId(userId)

        if (project) {
          return (
            <Text
              color={textColor}
              fontSize="md"
              fontWeight="500"
              style={{
                overflowWrap: 'break-word',
                wordBreak: 'break-all',
                textAlign: 'center',
              }}
              data-test-id={`reports-total-by-resource-table-cell-currency-${id}`}
            >
              {project.currency}
            </Text>
          );
        } else {
          return null;
        }
      },
    }),
  ];
  const [data, setData] = useState(() => []);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting
    },
    pageCount: reportsByResourceMeta.totalPages,
    rowCount: reportsByResourceMeta.totalItems,
    onPaginationChange: setPagination,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="36px"
        minWidth="max-content"
        gap="4"
      >
        <Flex flexDirection="column">
          <Text as="b" fontSize="xl">
            {t('totalsByResource', { ns: ['labels'] })}{' '}
          </Text>
          <p> {t('acceptedHint', { ns: ['hints'] })}</p>
        </Flex>
      </Flex>
      <FakturaTable
        table={table}
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
        data-test-id="reports-total-by-resource-table"
      />
    </Flex>
  );
}
