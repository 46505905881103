import { useCallback, useEffect, useMemo, useState } from 'react';
import { t } from 'i18next';
import { format } from 'date-fns';
import {
  Badge,
  Checkbox,
  IconButton,
  SimpleGrid,
  Spacer,
  Spinner,
  Tooltip,
  useToast,
  Button,
  Flex,
  Text,
  useColorModeValue,
  Stack,
  Select,
  Input,
  Td,
  Tr,
} from '@chakra-ui/react';

import {
  PaginationState,
  createColumnHelper,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import FakturaTable from 'components/tables/FakturaTable';
import { useProjectStore } from 'contexts/globalStoreProjects';
import useLoaderStore from 'contexts/globalStore';
import { RepeatIcon } from '@chakra-ui/icons';
import { useBookingStore } from 'contexts/globalStoreTimeBookings';
import { useInvoicesStore } from 'contexts/globalStoreInvoices';
import { tableStorage } from 'contexts/tableStorage';
import {
  getMonthRangeFromDateValue,
  zonedEndOfMonth
} from 'utils/dateHelpers';
import useUserDataRolesStore from 'contexts/authStore';
import { checkIsUserFinancialAdminInAnyProject } from 'utils/roleHelpers';
import { checkIfInvoiceDataIsInProject } from 'utils/valuesHelpers';
import { PAGE_INDEX_DEFAULT, PAGE_SIZE_DEFAULT } from 'variables/pagination';

type RowObj = {
  select: string;
  ids: string;
  taskId: number;
  userId: number;
  totalDuration: number;
  clientRate: number;
  totalClientCost: number;
  totalResourceCost: number;
  profit: number;
};

export default function SearchTableCreateInvoice(
  { setNewInvoiceCreatedTrigger }:
    { setNewInvoiceCreatedTrigger: (value: boolean) => void }
) {
  const textColor = useColorModeValue('navy.700', 'white');
  const toast = useToast();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const formatDate = useBookingStore((state) => state.formatDate);

  const isInitDone = useInvoicesStore((state) => state.isInitDone);

  const allProjectUsers = useProjectStore((state) => state.allProjectUsers);

  const [userFinancialAdminProjects, setUserFinancialAdminProjects] = useState([]);
  const defaultProjectId = useMemo(() => userFinancialAdminProjects?.[0]?.id, [userFinancialAdminProjects]);

  const { fakturaTasks } = useProjectStore(
    (state: any) => ({ fakturaTasks: state.allTasks }),
  );
  const { fakturaInvoiceCreate } = useInvoicesStore(
    (state: any) => ({
      fakturaInvoiceCreate: state.invoiceCreate,
    }),
  );

  const { invoiceCreateMeta } = useInvoicesStore(
    (state: any) => ({
      invoiceCreateMeta: state.invoiceCreateMeta,
    }),
  );

  const { fakturaInvoiceCurrency } = useInvoicesStore(
    (state: any) => ({
      fakturaInvoiceCurrency: state.invoiceCurrency,
    }),
  );

  const { fakturaTotalClientCost } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalClientCost: state.totalClientCost,
    }),
  );
  const { fakturaTotalDurationSum } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalDurationSum: state.totalDurationSum,
    }),
  );
  const { fakturaTotalResourceCostSum } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalResourceCostSum: state.totalResourceCostSum,
    }),
  );
  const { fakturaTotalProfitSum } = useInvoicesStore(
    (state: any) => ({
      fakturaTotalProfitSum: state.totalProfitSum,
    }),
  );
  const toISOWithoutTimezone = (d: any) => {
    let pad = (number: number) => (number < 10 ? '0' + number : number);

    return (
      d.getFullYear() +
      '-' +
      pad(d.getMonth() + 1) +
      '-' +
      pad(d.getDate()) +
      'T' +
      pad(d.getHours()) +
      ':' +
      pad(d.getMinutes()) +
      ':' +
      pad(d.getSeconds()) +
      '.' +
      (d.getMilliseconds() / 1000).toFixed(3).slice(2, 5) +
      'Z'
    );
  };
  const beginningOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setDate(1); // Set to the 1st of the current month
    date.setHours(0, 0, 0, 0); // Set time to the start of the day
    return toISOWithoutTimezone(date);
  };

  const endOfCurrentMonth = () => {
    let date = new Date(); // Current date
    date.setMonth(date.getMonth() + 1); // Add one month
    date.setDate(0); // Set to the last day of the previous month
    date.setHours(23, 59, 59, 999); // Set time to the end of the day
    return date.toISOString();
  };

  const fetchFakturaInvoiceCreate = useInvoicesStore(
    (state) => state.fetchInvoiceCreate,
  );
  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);
  const resetInvoiceList = useInvoicesStore(
    (state) => state.resetInvoiceCreate,
  );
  const resetInvoiceTotals = useInvoicesStore((state) => state.resetTotals);
  const getTimesheet = useInvoicesStore((state) => state.getTimesheet);
  function convertMinutesToHoursAndMinutes(minutes: number) {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  }
  const [startDate, setStartDate] = useState(
    formatDate(beginningOfCurrentMonth(), 'date'),
  );

  const [endDate, setEndDate] = useState(
    formatDate(endOfCurrentMonth(), 'date'),
  );

  const [projectId, setProjectId] = useState<number | null>(null);
  const handleProjectIdChange = (event: any) => {
    const newProjectId = event.target.value;
    setProjectId(newProjectId);
  };

  const [activeProjectsOnly, setActiveProjectsOnly] = useState<boolean>(true);
  // const handleActiveProjectsToggle = (value: boolean) => {
  //   const newActiveProjectsOnlyValue = value;
  //   setActiveProjectsOnly(newActiveProjectsOnlyValue);
  // };

  const isFirefox = useMemo(() => navigator.userAgent.toLowerCase().includes('firefox'), []);

  const [data, setData] = useState(() => []);

  const [taskData, setTaskData] = useState(() => []);
  const [currencyData, setCurrencyData] = useState(() => []);
  const currency = useMemo(() => currencyData[0]?.currency, [currencyData]);

  const handleStartDateChange = (e: any) => {
    const { startDay, endDay } = getMonthRangeFromDateValue(e.target.value);
    setStartDate(startDay);
    setEndDate(endDay);
    setData(fakturaInvoiceCreate);
    setCurrencyData(fakturaInvoiceCurrency);
  };

  useEffect(() => {
    setLoadingState(true);
    let isCurrent = true;

    if (isCurrent) {
      setData(fakturaInvoiceCreate);
      setTaskData(fakturaTasks)
      setCurrencyData(fakturaInvoiceCurrency);
    }
    setLoadingState(false);
    return () => {
      isCurrent = false;
    };
  }, [fakturaInvoiceCreate, fakturaInvoiceCurrency, fakturaTasks, setLoadingState]);

  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<RowObj>();

  const columns = [
    columnHelper.accessor('select', {
      id: 'select',
      cell: ({ row }: any) => {
        const id = row.original.id;

        return (
          <>
            <Checkbox
              id="select"
              isChecked={row.getIsSelected() || false}
              onChange={row.getToggleSelectedHandler()}
              colorScheme="brandScheme"
              data-test-id={`invoices-create-table-checkbox-selected-${id}`} />
          </>
        );
      },
    }),
    columnHelper.accessor('taskId', {
      id: 'taskId',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-task"
        >
          {t('taskHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info: any) => {
        const id = info.row.original.ids;
        const taskId = info.row.original.taskId;
        const task = taskData.find((task) => Number(task.id) === Number(taskId));
        if (task) {
          return (
            <Badge
              colorScheme="purple"
              fontSize="sm"
              fontWeight="500"
              size="sm"
              key={taskId}
              data-test-id={`invoices-create-table-cell-task-${id}`}
            >
              {task.name}
            </Badge>
          );
        }
      },
    }),
    columnHelper.accessor('totalDuration', {
      id: 'totalDuration',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-total-amount"
        >
          {t('totalAmountHeader', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        const totalMinutes = info.getValue();
        const formattedDuration = convertMinutesToHoursAndMinutes(totalMinutes);

        return (

          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-total-amount-${id}`}>
            {formattedDuration}
          </Text>
        );
      },
    }),

    columnHelper.accessor('clientRate', {
      id: 'clientRate',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-client-rate"
        >
          {t('clientRate', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-client-rate-${id}`}>
            {info.getValue()} {currency}/h
          </Text>
        );
      },
    }),

    columnHelper.accessor('totalClientCost', {
      id: 'totalClientCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-total-client-cost"
        >
          {t('total_client_cost', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-total-client-cost-${id}`}>
            {info.getValue()} {currency}
          </Text>
        );
      },
    }),

    columnHelper.accessor('totalResourceCost', {
      id: 'totalResourceCost',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-total-resource-cost"
        >
          {t('total_resource_cost', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-total-resource-cost-${id}`}>
            {info.getValue()} {currency}
          </Text>
        );
      },
    }),
    columnHelper.accessor('profit', {
      id: 'profit',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
          data-test-id="invoices-create-table-header-profit"
        >
          {t('profit', { ns: ['labels'] })}
        </Text>
      ),
      cell: (info) => {
        const id = info.row.original.ids;
        return (
          <Text color={textColor} fontSize="md" fontWeight="500" data-test-id={`invoices-create-table-cell-profit-${id}`}>
            {info.getValue()} {currency}
          </Text>
        );
      },
    }),
  ];

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: PAGE_INDEX_DEFAULT,
    pageSize: PAGE_SIZE_DEFAULT,
  });

  const [sorting, setSorting] = useState<SortingState>([]);

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      rowSelection,
      sorting
    },
    pageCount: invoiceCreateMeta?.totalPages || 0,
    rowCount: invoiceCreateMeta?.totalItems || 0,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  const tableName = useMemo(() => 'invoicesCreate', []);

  const chosenBookingIds = useMemo(() =>
    table.getSelectedRowModel().rows.map((row) => {
      const ids = row.original.ids;
      const idsToNum = Number(ids);

      if (isNaN(idsToNum)) {
        return ids.split(',').map(Number);
      }
      return [idsToNum];
    }).flat(),
    // Don't change table.getSelectedRowModel().rows to just table in dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table.getSelectedRowModel().rows]);

  useEffect(() => {
    const {
      globalFilter: newGlobalFilter,
      startDate: newStartDate,
      activeProjectsOnly: newActiveProjectsOnly
    } = tableStorage.fetch(tableName);

    setGlobalFilter(newGlobalFilter);
    setActiveProjectsOnly(newActiveProjectsOnly);

    const selectedDateValue = newStartDate ?? format(new Date(), 'yyyy-MM-dd');
    const { startDay, endDay } = getMonthRangeFromDateValue(selectedDateValue);
    setStartDate(startDay);
    setEndDate(endDay);
  }, [tableName])

  useEffect(() => {
    tableStorage.save(tableName, {
      globalFilter,
      startDate,
      projectId,
      activeProjectsOnly
    })
  }, [globalFilter, startDate, projectId, tableName, activeProjectsOnly])

  const refreshData = useCallback(() => {
    setLoadingState(true);
    table.resetRowSelection();
    fetchFakturaInvoiceCreate(
      startDate,
      zonedEndOfMonth(new Date(endDate)),
      projectId || defaultProjectId,
      'data',
      [],
      activeProjectsOnly,
      pagination.pageIndex + 1,
      pagination.pageSize,
      sorting[0]?.id || null,
      sorting[0]?.desc ? 'desc' : 'asc'
    )
      .then((res) => {
        setLoadingState(false);
        if (!res || res.type !== 'success') {
          toast({
            title: t('error', { ns: ['labels'] }),
            description: res.message,
            status: res.type,
          });
        }
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Faktura Invoices:', error);
        toast({
          title: t('error', { ns: ['labels'] }),
          description: error.message,
          status: error.type,
        });
      });
  }, [setLoadingState,
    table,
    fetchFakturaInvoiceCreate,
    startDate,
    endDate,
    projectId,
    defaultProjectId,
    activeProjectsOnly,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    toast]);

  useEffect(() => {
    let isCurrent = true;
    setLoadingState(true);
    if (isCurrent && isInitDone) {
      if (startDate && endDate && (projectId || defaultProjectId)) {
        refreshData();
      } else {
        resetInvoiceList();
        resetInvoiceTotals();
      }
    }
    table.resetRowSelection();
    setLoadingState(false);

    return () => {
      isCurrent = false;
    };
  }, [startDate,
    endDate,
    projectId,
    isInitDone,
    refreshData,
    resetInvoiceList,
    resetInvoiceTotals,
    setLoadingState,
    defaultProjectId,
    table]);

  const handleTimesheetButtonClick = useCallback(() => {
    setLoadingState(true);
    getTimesheet(startDate,
      zonedEndOfMonth(new Date(endDate)),
      projectId || defaultProjectId,
      'timesheet',
      activeProjectsOnly,
      chosenBookingIds || [])
      .then(() => {
        setLoadingState(false);
      })
      .catch((error) => {
        setLoadingState(false);
        console.error('Error fetching Timesheet Invoices:', error);
      });
  }, [setLoadingState,
    getTimesheet,
    startDate,
    endDate,
    projectId,
    defaultProjectId,
    activeProjectsOnly,
    chosenBookingIds]);

  const userData = useUserDataRolesStore((state) => state.userData);

  const fetchProjects = useProjectStore((state) => state.fetchProjects);
  const getUserAdminProjects = useProjectStore((state) => state.getUserAdminProjects);

  useEffect(() => {
    const fetchAndSetFinancialAdminProjects = async () => {
      const projects = await getUserAdminProjects(userData, true);

      const projectsWithInvoiceData = projects.filter(project => {
        const doesProjectHaveInvoiceData = checkIfInvoiceDataIsInProject(project);
        return doesProjectHaveInvoiceData;
      })
      setUserFinancialAdminProjects(projectsWithInvoiceData);
    };

    fetchProjects(true).then(() => {
      fetchAndSetFinancialAdminProjects();
    })
  }, [userData, fetchProjects, getUserAdminProjects]);

  useEffect(() => {
    const {
      projectId: newProjectId,
    } = tableStorage.fetch(tableName);

    if (newProjectId && userFinancialAdminProjects.includes(newProjectId))
      setProjectId(newProjectId);
  }, [tableName, userFinancialAdminProjects])

  const invoicesDataExist = useMemo(() =>
    Boolean(table?.getRowModel()?.rows?.length),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table?.getRowModel()]);

  return (
    <Flex direction="column" w="100%" overflowX={{ sm: 'auto', lg: 'auto' }}>
      <Flex
        flexDirection={{ sm: 'column', lg: 'row' }}
        align={{ sm: 'flex-start', lg: 'center' }}
        justify={{ sm: 'flex-start', lg: 'space-between' }}
        w="100%"
        px="22px"
        mb="10px"
        minWidth="max-content"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 2 }} gap="5px">
          <Stack direction="column" gap="20px" width="250px">
            <Tooltip label={t('chooseMonth', { ns: ['labels'] })}>
              {/* Input with type "month" is not supported by FF so we use "date" */}
              <Input
                type={isFirefox ? 'date' : 'month'}
                size="md"
                color={textColor}
                value={(isFirefox ? startDate : formatDate(startDate, 'month')) || ''}
                data-test-id={`invoice-create-table-filter-startDate`}
                onChange={handleStartDateChange}
                onKeyDown={(e) => e.preventDefault()}
              />
            </Tooltip>

          </Stack>

          {userFinancialAdminProjects?.length ? (
            <Stack direction="column" gap="20px" width="250px">
              <Select
                value={projectId || defaultProjectId}
                data-test-id={`invoice-create-table-filter-project`}
                onChange={handleProjectIdChange}
              >
                {[...userFinancialAdminProjects]?.sort(
                  (a, b) => a.title.localeCompare(b.title)
                ).map((project: any) => (
                  <option key={project.id} value={project.id}>
                    {project.title}
                  </option>
                ))}
              </Select>
            </Stack>
          ) : null}
        </SimpleGrid>
        <Stack direction="column" gap="20px" justifyContent={'center'}>
          {/* <Checkbox
          // isChecked={activeProjectsOnly}
          // onChange={(event: any) => {
          //   handleActiveProjectsToggle(event.target.checked);
          // }}
          >
            {t("onlyActiveProjects", { ns: ["labels"] })}
          </Checkbox> */}
        </Stack>

        {/* Only display Spacer on larger screens */}
        <Spacer display={{ sm: 'none', lg: 'block' }} />

        {loadingState ? (
          <Spinner />
        ) : (
          <Stack direction="column" gap="20px" justifyContent={'center'}>

            <IconButton
              aria-label="Reload"
              variant="brand"
              icon={<RepeatIcon />}
              data-test-id={`invoice-create-table-refresh-button`}
              onClick={refreshData}
            >
              Reload
            </IconButton>
          </Stack>
        )}
      </Flex>
      <Flex
        align={{ sm: 'flex-start', lg: 'flex-start' }}
        justify={{ sm: 'flex-start', lg: 'flex-start' }}
        w="100%"
        px="22px"
        mb="36px"
        minWidth="max-content"
        alignItems="center"
        gap="4"
      >
        <SimpleGrid columns={{ base: 1, xl: 3 }} gap="5px">
          {checkIsUserFinancialAdminInAnyProject(allProjectUsers, userData.id) ? (
            <Stack direction="column" gap="20px">
              <Button
                cursor="pointer"
                variant="brand"
                isDisabled={data.length === 0}
                data-test-id={`invoice-create-table-create-button`}
                onClick={() => {
                  setLoadingState(true);
                  fetchFakturaInvoiceCreate(
                    startDate,
                    zonedEndOfMonth(new Date(endDate)),
                    projectId || defaultProjectId,
                    'create',
                    chosenBookingIds || [],
                    activeProjectsOnly
                  )
                    .then(() => {
                      refreshData();
                      table.resetRowSelection();
                      setLoadingState(false);
                      setNewInvoiceCreatedTrigger(true);
                    })
                    .catch((error) => {
                      setLoadingState(false);
                      console.error('Error fetching Creating Invoices:', error);
                    });
                }}
              >
                {t('create', { ns: ['actions'] })}
              </Button>
            </Stack>
          ) : null}

          <Stack direction="column" gap="20px">
            <Button
              cursor="pointer"
              variant="brand"
              isDisabled={data.length === 0}
              data-test-id={`invoice-create-table-preview-button`}
              onClick={() => {
                setLoadingState(true);
                fetchFakturaInvoiceCreate(
                  startDate,
                  zonedEndOfMonth(new Date(endDate)),
                  projectId || defaultProjectId,
                  'preview',
                  chosenBookingIds || [],
                  activeProjectsOnly
                )
                  .then(() => {
                    setLoadingState(false);
                  })
                  .catch((error) => {
                    setLoadingState(false);
                    console.error('Error fetching Preview Invoices:', error);
                  });
              }}
            >
              {t('preview', { ns: ['actions'] })}
            </Button>
          </Stack>
          <Stack direction="column" gap="20px">
            <Button
              cursor="pointer"
              isDisabled={data.length === 0}
              variant="brand"
              data-test-id={`invoice-create-table-timesheet-button`}
              onClick={() => handleTimesheetButtonClick()}
            >
              {t('timesheetPdf', { ns: ['actions'] })} PDF
            </Button>
          </Stack>
        </SimpleGrid>
      </Flex>

      {invoicesDataExist ? (<FakturaTable
        table={table}
        additionalBottomRows={
          <Tr>
            <Td></Td>
            <Td>{t("totalsProvisionals", { ns: ["labels"] })}</Td>
            <Td>
              {convertMinutesToHoursAndMinutes(fakturaTotalDurationSum)}
            </Td>
            <Td></Td>
            <Td>
              {fakturaTotalClientCost} {currency}
            </Td>
            <Td>
              {fakturaTotalResourceCostSum} {currency}
            </Td>
            <Td>
              {fakturaTotalProfitSum} {currency}
            </Td>
          </Tr>
        }
        loadingState={loadingState}
        pagination={pagination}
        setPagination={setPagination}
        dataTestId={`invoice-create-table`}
      />) : null
      }
    </Flex >
  );
}
