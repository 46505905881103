import {
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from '@chakra-ui/react';
import Card from 'components/card/Card';

import SearchTableReports from './components/SearchTableReports';
import SearchTableMonthlyReport from './components/SearchTableMonthlyReport';
import SearchTableByResource from './components/SearchTableByResource';
import SearchTableTotalsByResource from './components/SearchTableTotalsByResource';
import { t } from 'i18next';
import { useReportsStore } from "../../../contexts/globalStoreReports";
import { useEffect, useState } from "react";
import { useProjectStore } from "../../../contexts/globalStoreProjects";
import { useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';
import useUserDataRolesStore from 'contexts/authStore';
import useLoaderStore from 'contexts/globalStore';
import SearchTableProjectReports from './components/SearchTableProjectReports';

export default function Reports() {
  const loadingState = useLoaderStore((state) => state.isLoading);
  const setLoadingState = useLoaderStore((state) => state.setLoader);

  const fetchProjects = useProjectStore((state) => state.fetchProjects);
  const fetchUsers = useFakturaUsersStore((state) => state.fetchUsers);

  const set = useReportsStore((state) => state.set);

  const userData = useUserDataRolesStore((state) => state.userData);

  const [userAdminProjects, setUserAdminProjects] = useState([]);
  const [userFinancialAdminProjects, setUserFinancialAdminProjects] = useState([]);

  const { getUserAdminProjects } = useProjectStore(
    (state: any) => ({ getUserAdminProjects: state.getUserAdminProjects }),
  );

  useEffect(() => {
    let isCurrent = true;
    setLoadingState(true);

    const fetchData = async () => {
      const res1 = fetchUsers();
      const res2 = fetchProjects();
      await Promise.all([res1, res2]).then(() => {
        set((state: any) => {
          state.isInitDone = true
        })
        setUserAdminProjects(getUserAdminProjects(userData, false))
        setUserFinancialAdminProjects(getUserAdminProjects(userData, true))
      });
    };
    if (isCurrent) {
      fetchData().then(() => setLoadingState(false));
    }

    return () => {
      isCurrent = false;
    };
  }, [userData, getUserAdminProjects, fetchProjects, fetchUsers, set, setLoadingState]);

  return (
    <Flex direction="column" pt={{ sm: '125px', lg: '75px' }}>
      {userAdminProjects?.length ?
        <Tabs variant="enclosed">
          <TabList>
            <Tab>{t('report', { ns: ['labels'] })}</Tab>
            {userFinancialAdminProjects?.length ? (<>
              <Tab data-test-id="report-monthly-tab">{t('monthlyReport', { ns: ['labels'] })}</Tab>
              <Tab data-test-id="report-by-resource-tab">{t('byResource', { ns: ['labels'] })}</Tab>
              <Tab data-test-id="report-project-tab">{t('projectsReport', { ns: ['labels'] })}</Tab>
            </>) : null}
          </TabList>

          <TabPanels>
            <TabPanel>
              <Card px="0px">
                <SearchTableReports />
              </Card>
              <br />
              {userFinancialAdminProjects?.length ? (
                <Card px="0px">
                  <SearchTableTotalsByResource />
                </Card>) : null}
            </TabPanel>
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableMonthlyReport />
                </Card>
              </TabPanel>
            ) : null}
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableByResource />
                </Card>
              </TabPanel>
            ) : null}
            {userFinancialAdminProjects?.length ? (
              <TabPanel>
                <Card px="0px">
                  <SearchTableProjectReports />
                </Card>
              </TabPanel>
            ) : null}
          </TabPanels>
        </Tabs>
        : loadingState
          ? <Spinner />
          : <Text>{t('youHaveNoAdminProjects', { ns: ['labels'] })}</Text>
      }
    </Flex>
  );
}
