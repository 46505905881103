import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Stack,
  FormControl,
  Text,
  Flex,
  IconButton,
  Container,
  SimpleGrid,
  InputGroup,
  Input,
  InputRightAddon,
  Select,
  Card,
  useColorModeValue,
  useToast,
  FormLabel,
} from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { ProjectUser, useProjectStore } from 'contexts/globalStoreProjects';
import { t } from 'i18next';
import * as yup from 'yup';
import useUserDataRolesStore from 'contexts/authStore';
import { User, useFakturaUsersStore } from 'contexts/globalStoreFakturaUsers';
import { ProjectUserRoles, ProjectUserRolesArray } from 'enums/userRoles';
import { checkIfUserFakturaAdmin, checkIfUserProjectCreator } from 'utils/roleHelpers';
import useFormErrorsStore from 'contexts/formErrorsStore';

const validationSchemaSingleUserNoRates = yup.object().shape({
  userId: yup.number().typeError('typeErrorUser').required('typeErrorUser'),
  role: yup.string().required('roleRequired'),
  hoursPerMonth: yup
    .number()
    .when('role', {
      is: (val: string) => (val === ProjectUserRoles.ADMIN
        || val === ProjectUserRoles.FINANCIAL_ADMIN
        || val === ProjectUserRoles.INVOICE_APPROVER),
      then: (schema) => schema.nullable().test(
        'is-null-or-empty',
        'mustBeEmptyForAdmins',
        (value) => value === null || value === 0 || value === undefined
      ),
      otherwise: (schema) => schema.nullable(),
    }),
});

const validationSchemaNoRates = yup.object().shape({
  users: yup
    .array()
    .of(validationSchemaSingleUserNoRates)
    .min(1, 'atLeastOneTechAdmin')
    .required()
    .test(
      'at-least-one-admin',
      'atLeastOneTechAdmin',
      (users) => users && users.some(user => user.role === ProjectUserRoles.ADMIN)
    ),
});

export const ProjectPermissions = ({
  viewOnly, isUserFinancialAdminOnProject
}: {
  viewOnly: boolean,
  isUserFinancialAdminOnProject: boolean
}) => {
  const toast = useToast();

  const projectUsers = useProjectStore((state) => state.projectUsers);
  const projectId = useProjectStore((state) => state.project.id);
  const projectUsersUserIds = useMemo(() => {
    return projectUsers
      .map((user) => user.userId)
      .filter((userId) => userId !== null);
  }, [projectUsers]);

  const userData = useUserDataRolesStore((state) => state.userData);
  const userRoles = useUserDataRolesStore((state) => state.userRoles);

  const setErrors = useFormErrorsStore((state) => state.setErrors);
  const showErrors = useFormErrorsStore((state) => state.showErrors);
  const deleteError = useFormErrorsStore((state) => state.deleteError);
  const deleteErrorsContainingString = useFormErrorsStore((state) => state.deleteErrorsContainingString);

  const isUserProjectCreator = useMemo(() => checkIfUserProjectCreator(userRoles), [userRoles]);
  const isUserFakturaAdmin = useMemo(() => checkIfUserFakturaAdmin(userRoles), [userRoles]);

  const allUsers = useFakturaUsersStore((state) => state.users);
  const fetchUsers = useFakturaUsersStore((state) => state.fetchUsers);

  const addNewUserToProject = useProjectStore(
    (state) => state.addUserToProject,
  );
  const deleteUserFromProjectList = useProjectStore(
    (state) => state.deleteUserFromProject,
  );

  const handleUserDataChange = useProjectStore(
    (state) => state.changeUserInProject,
  );

  const [eachFieldProjectUserValidation, setEachFieldProjectUserValidation] =
    useState([]);

  const getValidationError = useCallback((index: number, fieldName: string) => {
    return eachFieldProjectUserValidation?.[index]?.[fieldName]?.error || null
  }, [eachFieldProjectUserValidation]);

  useEffect(() => { fetchUsers() }, [fetchUsers]);

  const handleAddSelect = useCallback(() => {
    addNewUserToProject({
      userId: null,
      role: '',
      userRate: 0,
      clientRate: 0,
      hoursPerMonth: 0,
      projectId: projectId || null,
    });
  }, [addNewUserToProject, projectId]);

  const handleDeleteSelect = useCallback((indexToDelete: number) => {
    deleteUserFromProjectList(indexToDelete);
  }, [deleteUserFromProjectList]);

  const handleUserData = useCallback((index: number, field: any, value: any) => {
    if (projectUsers.length > 1 && projectUsersUserIds.includes(field.user_id)) {
      toast({
        title: t('error', { ns: ['labels'] }),
        description: t('usersShouldBeUnique', { ns: ['hints'] }),
      });
    }
    handleUserDataChange(index, field, value);
  }, [handleUserDataChange, projectUsers.length, projectUsersUserIds, toast]);

  const setTrue = useProjectStore((state) => state.setToTrue);

  const validateFormData = useCallback(async (formData: any) => {
    try {
      await validationSchemaNoRates.validate(formData);
      setTrue('permissionValidation', true);
    } catch (error) {
      setTrue('permissionValidation', false);
    }
  }, [setTrue]);

  useEffect(
    () => {
      validateFormData({ users: projectUsers });
    },
    [validateFormData, projectUsers],
  );

  const validateSingleField = useCallback(async (path: string, user: ProjectUser) => {
    try {
      // We need to validate the whole object to check the role
      await validationSchemaSingleUserNoRates.validateAt(path, user);
      return { valid: true, error: '' };
    } catch (e: any) {
      return { valid: false, error: e.errors.join(', ') };
    }
  }, []);

  useEffect(() => {
    setEachFieldProjectUserValidation([]);

    const mappedResult = projectUsers.map(async (user: ProjectUser) => ({
      userId: await validateSingleField('userId', user),
      role: await validateSingleField('role', user),
      hoursPerMonth: await validateSingleField(
        'hoursPerMonth',
        user,
      ),
    }));

    Promise.all(mappedResult).then((res) =>
      setEachFieldProjectUserValidation(res),
    );
  }, [projectUsers, validateSingleField]);

  useEffect(() => {
    const userError = t('userError', { ns: ['errors'] });
    const atLeastOneTechAdminError = `${userError}: ${t('atLeastOneTechAdmin', { ns: ['hints'] })}`;

    if (projectUsers?.length && projectUsers.some(projectUser => projectUser.role === ProjectUserRoles.ADMIN)) {
      deleteError(atLeastOneTechAdminError);
      const usersWithErrors = eachFieldProjectUserValidation.filter(
        (user: ProjectUser) => Object.values(user).some(
          (field: { valid: boolean, error: string }) => !field.valid
        ),
      );

      if (usersWithErrors.length > 0) {
        const errorsFields = usersWithErrors.map(
          (user: any) => Object.values(user)
        )

        const errors = errorsFields
          .filter((fieldsArr: any) => fieldsArr.some((field: any) => !field.valid))
          .map((fieldsArr: any) => fieldsArr.filter((field: any) => !field.valid))
          .flat();

        const errorsForShow: string[] = errors.map(
          (field: any) => `${userError}: ${t(field.error, { ns: ['hints'] })}`
        )

        setErrors(errorsForShow);
      } else {
        deleteErrorsContainingString(userError);
      }
    } else {
      setErrors([atLeastOneTechAdminError]);
    }
  }, [deleteError, deleteErrorsContainingString, eachFieldProjectUserValidation, projectUsers, setErrors]);

  const textColor = useColorModeValue('navy.700', 'white');

  const addDefaultAdmin = useCallback(() => {
    const newUser: ProjectUser = {
      userId: userData.id,
      role: ProjectUserRoles.ADMIN,
      userRate: 0,
      clientRate: 0,
      hoursPerMonth: 0,
      projectId: projectId || null,
    }
    addNewUserToProject(newUser);
  }, [addNewUserToProject, projectId, userData.id]);

  const [isInit, setIsInit] = useState(true);

  useEffect(() => {
    if (projectUsers.length > 0 || !isInit) {
      return
    }
    // Quick fix of reset data (in AddEdit) after adding default admin
    setTimeout(() => {
      addDefaultAdmin()
    }, 1);

    setIsInit(false);
  }, [addDefaultAdmin, isInit, projectUsers.length]);

  const getUserRolesAvailableForSelect = useCallback((userId: number, currentRole: string) => {
    const currentUserRoles = projectUsers
      .filter(projectUser => projectUser.userId === userId && projectUser.role !== currentRole)
      .map(projectUser => projectUser.role)

    return ProjectUserRolesArray.filter((role) => !currentUserRoles.includes(role))
  }, [projectUsers]);

  return (
    <>
      <Flex my={4} pr={4}>
        {!viewOnly ? <Button variant="outline" data-testid="add-project-permission-button" onClick={handleAddSelect}>
          Add permission
          <AddIcon ml={2} />
        </Button> : null}
      </Flex>
      <SimpleGrid columns={{ base: 1, md: 2 }} gap="4">
        {projectUsers?.map((projectUser: ProjectUser, index: number) => {
          const availableForSelectUserRoles =
            getUserRolesAvailableForSelect(projectUser.userId, projectUser.role)

          let cantAddAllRolesExistForThisUser = false;

          if (!availableForSelectUserRoles.length) {
            cantAddAllRolesExistForThisUser = true
          }

          return (
            <Container key={index + 5000}>
              <Card p={4}>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  alignItems="center"
                  mb={3}
                >
                  <InputGroup>
                    <FormControl>
                      <Stack direction="column">
                        <FormLabel
                          ms="10px"
                          fontSize="sm"
                          fontWeight="bold"
                          _hover={{ cursor: 'pointer' }}
                          mb={0}
                        >
                          {t('user', { ns: ['labels'] }) + '*'}
                        </FormLabel>
                        <Select
                          value={projectUser.userId || ''}
                          placeholder={t('selectUser', { ns: ['labels'] })}
                          disabled={viewOnly}
                          data-test-id={`permission-${index}-user-select`}
                          onChange={(event) => handleUserData(
                            index,
                            'userId',
                            Number(event.target.value)
                          )}
                        >
                          {allUsers.map(
                            (user: User) => (
                              <option key={user.userId} value={user.userId}>
                                {user.name} ({user.email}){user.status === 'ACTIVE' ? '' : ` (${t('inactive', { ns: ['labels'] })})`}
                              </option>
                            )
                          )}
                        </Select>
                        {showErrors && getValidationError(index, 'userId') ?
                          <Text data-test-id={`permission-${index}-user-error`} style={{ marginTop: 0 }} pl={2} fontSize="sm" color="red.500">
                            {t(getValidationError(index, 'userId'), { ns: ['hints'] })}{' '}
                            &nbsp;
                          </Text> : null}
                      </Stack>
                    </FormControl>
                    <IconButton
                      ml="4"
                      colorScheme="red"
                      aria-label="Delete Select"
                      icon={<DeleteIcon />}
                      isDisabled={viewOnly}
                      alignSelf={'end'}
                      data-test-id={`permission-${index}-delete-button`}
                      onClick={() => handleDeleteSelect(index)}
                    />
                  </InputGroup>
                </Stack>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  alignItems="center"
                  mb={3}
                >
                  <FormControl>
                    <Stack direction="column">
                      <FormLabel
                        ms="10px"
                        htmlFor="costCenterNonBillable"
                        fontSize="sm"
                        fontWeight="bold"
                        _hover={{ cursor: 'pointer' }}
                        mb={0}
                      >
                        {t('role', { ns: ['labels'] }) + '*'}
                      </FormLabel>
                      {cantAddAllRolesExistForThisUser
                        ? <Text>{t('allRolesInList', { ns: ['hints'] })}</Text>
                        : <Select
                          value={projectUser.role || ''}
                          placeholder={t('selectRole', { ns: ['labels'] })}
                          disabled={viewOnly ||
                            (projectUser.role === ProjectUserRoles.FINANCIAL_ADMIN
                              && !isUserFinancialAdminOnProject
                              && !isUserProjectCreator && !isUserFakturaAdmin)}
                          onChange={(event) => handleUserData(index, 'role', event.target.value)}
                          data-test-id={`permission-${index}-role-select`}
                        >
                          {availableForSelectUserRoles.includes(ProjectUserRoles.USER) && (
                            <option value={ProjectUserRoles.USER}>User</option>
                          )}
                          {availableForSelectUserRoles.includes(ProjectUserRoles.ADMIN) && (
                            <option value={ProjectUserRoles.ADMIN}>Technical Admin</option>
                          )}
                          {availableForSelectUserRoles.includes(ProjectUserRoles.FINANCIAL_ADMIN) && (
                            <option
                              value={ProjectUserRoles.FINANCIAL_ADMIN}
                              disabled={!isUserFakturaAdmin && !isUserFinancialAdminOnProject}
                            >
                              Financial Admin{!isUserFakturaAdmin && !isUserFinancialAdminOnProject ?
                                ` (${t('cantChange', { ns: ['labels'] })})` : ''}
                            </option>
                          )}
                          {availableForSelectUserRoles.includes(ProjectUserRoles.INVOICE_APPROVER) && (
                            <option
                              value={ProjectUserRoles.INVOICE_APPROVER}
                              disabled={!isUserFinancialAdminOnProject}
                            >
                              Invoice Approver{!isUserFinancialAdminOnProject ?
                                ` (${t('cantChange', { ns: ['labels'] })})` : ''}
                            </option>
                          )}
                        </Select>}
                      {showErrors && getValidationError(index, 'role') ?
                        <Text data-test-id={`permission-${index}-role-error`} style={{ marginTop: 0 }} pl={2} fontSize="sm" color="red.500">
                          {t(getValidationError(index, 'role'), { ns: ['hints'] })}{' '}
                          &nbsp;
                        </Text> : null}
                    </Stack>
                  </FormControl>
                </Stack>
                <SimpleGrid columns={{ base: 1, md: 1 }} spacingX="20px" mb={3}>
                  <FormLabel
                    ms="10px"
                    htmlFor="hoursPerMonth"
                    fontSize="sm"
                    fontWeight="bold"
                    _hover={{ cursor: 'pointer' }}
                  >
                    {t('hoursPerMonth', { ns: ['labels'] })}
                  </FormLabel>
                  <InputGroup size="md">
                    <Input
                      value={projectUser.hoursPerMonth || ''}
                      color={textColor}
                      placeholder={t('hoursPerMonth', { ns: ['labels'] })}
                      disabled={viewOnly || projectUser.role !== ProjectUserRoles.USER}
                      data-test-id={`permission-${index}-hours-per-month-input`}
                      onChange={(event) => handleUserData(index, 'hoursPerMonth', Number(event.target.value))}
                    />
                    <InputRightAddon children="/h" />
                  </InputGroup>
                  {showErrors && getValidationError(index, 'hoursPerMonth')
                    ? <Text data-test-id={`permission-${index}-hours-per-month-error`} m={0} p={0} pl={2} fontSize="sm" color="red.500">
                      {t(getValidationError(index, 'hoursPerMonth'), { ns: ['hints'] })}{' '}
                      &nbsp;
                    </Text> : null}
                </SimpleGrid>
              </Card>
            </Container>
          );
        })}
      </SimpleGrid>
    </>
  );
};

export default ProjectPermissions;
